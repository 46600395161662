import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import { Container, Row, Col } from 'react-bootstrap';


const IndexPage = () => (
  <Layout>
    <SEO title="ShoppRe influencer program" />
    <div>
      <a href="https://share.hsforms.com/1XKdpA7O6Qai3TdIX-L6RHw49oim" target="_blank">
        <img className="imgset" src="https://mcusercontent.com/8f7212d51aa9533c4788ccf05/images/f887579d-dd53-4933-b67a-dad8abbde559.jpeg" alt=""/>
      </a>
    </div>

    <h1 className="text-center mod-h1 mob-fnt01">ShoppRe introduces an amazing Influencer Program</h1>
    <h1 className="text-center f-s-28 mod-h1 mod-fnt02">earn up to $10 per shipment made through your link</h1>
    <div class="col-md-12 col-sm-12 col-xs-12">
      <Row className="text-center">
        <div class="col-md-6 col-sm-12 col-xs-12 sec2 no-pad" >
          <h1 className="f-s-28 sec2-txt mob-fnt03" >
          If you have a good social media
            <br/>
          presence on any platform then
            <br/>
          you can earn with us by sharing
            <br/>
          the link to our website.
        </h1>
        </div>
        <div class="col-md-6 col-sm-12 col-xs-12 text-center no-pad">
          <img style={{
            maxHeight:'80%',
          }} src="https://mcusercontent.com/8f7212d51aa9533c4788ccf05/images/a9bf7394-f3dd-40ca-9302-616318d40c1b.jpeg" alt="" />
          </div>
      </Row>
    </div>
    <div class="col-md-12 col-sm-12 col-xs-12">
      <h1 className="text-center f-s-35 mod-h1">Earn with just three easy steps</h1>
      <br/>
      <Row>
        <div class="col-md-4 col-sm-12 col-xs-12 text-center">
          <img style={{
            maxHeight:'40%',
          }} src="https://mcusercontent.com/8f7212d51aa9533c4788ccf05/images/54483de1-4422-49c3-8d2e-10507bc84e82.jpeg" alt="" />
          <p className="f-w-600 sec3-txt">
            Get a unique link
          </p>
          <p className="sec4-txt">
            Every qualified influencer
            <br/>
            will get a unique link
            <br/>
            generated by ShoppRe
          </p>
        </div>
        <div className="col-md-4 col-sm-12 col-xs-12 text-center">
          <img style={{
            maxHeight:'40%',
          }} src="https://mcusercontent.com/8f7212d51aa9533c4788ccf05/images/533ea62b-6a29-49b4-abce-0818d0648984.jpeg" alt="" />
          <p className="f-w-600 sec3-txt">
            Share the link
          </p>
          <p className="sec4-txt">
            Share your custom
            <br/>
            URL to any of your
            <br/>
            social media platforms
          </p>
        </div>
        <div className="col-md-4 col-sm-12 col-xs-12 text-center">
          <img style={{
            maxHeight:'40%',
          }} src="https://mcusercontent.com/8f7212d51aa9533c4788ccf05/images/d505014c-f2c5-45e9-b555-0d946e8061f0.jpeg" alt="" />
          <p className="f-w-600 sec3-txt">
            Earn rewards
          </p>
          <p className="sec4-txt">
            Get rewarded when
            <br/>
            someone signs up
            <br/>
            through your link
          </p>
        </div>
      </Row>
    </div>

    <div className="bg-F5F5F5">
      <h1 className="text-center f-s-35 mod-h1 txt-707070 pad-t-2">Frequently Asked Questions</h1>
      <div className="pad-4">
        <Row>
          <div class="col-md-6 col-sm-12">
            <p className="f-w-600">
              How does ShoppRe Influencer Program work?
            </p>
            <p>
              When your social media account qualifies for the ShoppRe
              Influencer Program, you will get a unique link to share on
              your social media platforms. When anyone clicks on the link
              to sign up at our website and then make a shipment, you will
              get rewarded.
            </p>
          </div>
          <div class="col-md-6 col-sm-12">
            <p className="f-w-600">
              How will I get rewarded?
            </p>
            <p>
              You will get rewarded every time we get a customer
              through your link. You will get a complimentary shipment
              of 5kg for the first conversion and then 10 USD
              subsequently on every other conversion afterward.
            </p>
          </div>
        </Row>
        <Row>
          <div class="col-md-6 col-sm-12">
            <p className="f-w-600">
              Do I qualify for the ShoppRe Influencer Program?
            </p>
            <p>
              To qualify for the ShoppRe Influencer Program:
              <br/>
              <span className="f-w-600">1.</span> You need to be an NRI or living outside India.
              <br/>
              <span className="f-w-600">2.</span> You need more than 5000 followers on any social media
              platform.
            </p>
          </div>
          <div class="col-md-6 col-sm-12">
            <p className="f-w-600">
              How do I apply for this?
            </p>
            <p>
              You can apply to the ShoppRe Influencer Program by
              simply contacting us here-support@shoppre.com. Make
              sure to share your name, place, and the link to your
              social media platform.
            </p>
          </div>
        </Row>
        <Row>
          <div class="col-md-6 col-sm-12">
            <p className="f-w-600">
              How will ShoppRe know how many people sign
              up through my link?
            </p>
            <p>
              The unique URL that will be shared with you, will be trackable.
              Therefore, it will allow us to see how many people came to
              ShoppRe through your link. And when they make a shipment,
              you will be rewarded.
            </p>
          </div>

          <Col>
          </Col>
        </Row>
      </div>
    </div>

    {/*<p>Welcome to your new Gatsby site.</p>*/}
    {/*<p>Now go build something great.</p>*/}
    {/*<div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>*/}
    {/*  <Image />*/}
    {/*</div>*/}


    {/*<Link to="/page-2/">Go to page 2</Link> <br />*/}
    {/*<Link to="/using-typescript/">Go to "Using TypeScript"</Link>*/}
  </Layout>
)

export default IndexPage
